import { api } from './configs/axiosConfig';

const endPoint = "/file-upload";

export const fileUploadAPI = {
    saveMemberDocs: async function (formData) {
        const response = await api.request({
            url: `${endPoint}/member-docs`,
            method: 'POST',
            data: formData
        });

        return response.data;
    },
    getGeneralDocs: async function (cusCode) {
        const response = await api.request({
            url: `${endPoint}/get-member-docs`,
            method: 'GET',
            params: { cusCode }
        });

        return response.data;
    },
    saveVehicleDocs: async function (formData) {
        const response = await api.request({
            url: `${endPoint}/vehicle-docs`,
            method: 'POST',
            data: formData
        });

        return response.data;
    },
    getVehicleDocs: async function (cusCode, vehRegCode) {
        const response = await api.request({
            url: `${endPoint}/get-vehicle-docs`,
            method: 'GET',
            params: { cusCode, vehRegCode }
        });

        return response.data;
    },
    deleteGeneralDocs: async function (fileId) {
        const response = await api.request({
            url: `${endPoint}/delete-member-docs`,
            method: 'DELETE',
            params: { fileId }
        });

        return response.data;
    },
    deleteVehicleDocs: async function (fileId) {
        const response = await api.request({
            url: `${endPoint}/delete-veh-docs`,
            method: 'DELETE',
            params: { fileId }
        });

        return response.data;
    }
}
