import React, { useState, useRef, useEffect } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast } from 'react-toastify';
import { memberAPI } from '../../../api/memberAPI';
import AllPcode from '../../../components/userPcode';

import MembershipTypeSearch from '../../../components/membershipTypeSearch';
import DocumentType from '../../../components/documentTypes';
import VehicleModelSearch from '../../../components/vehicleModelSearch';
import MemberSearch from '../../../components/memberSearch';
import Countries from '../../../components/allCountries';

import { common } from '../../../common/common';

import './memeberRegistration.css';

import Spinner from '../../../components/spinner';
import { fileUploadAPI } from '../../../api/fileUploadAPI';

export default function MemberRegistration() {
    const modalRef = useRef(null);

    const [membershipTypeCode, setMembershipTypeCode] = useState('');
    const [membershipTypeName, setMembershipTypeName] = useState('');
    const [selectedPcode, setSelectedPcode] = useState('');
    const [cusCode, setCusCode] = useState('');
    const [membershipNo, setMembershipNo] = useState('');

    const [membershipDate, setMembershipDate] = useState('');
    const [outstanding, setOutstanding] = useState(0);
    const [comments, setComments] = useState("");

    const [cusName, setCusName] = useState('');
    const [cusAdd, setCusAdd] = useState('');
    const [cusEmail, setCusEmail] = useState('');
    const [cusTel, setCusTel] = useState('');
    const [cusMobile, setCusMobile] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');

    const [generalDocCode, setGeneralDocCode] = useState('');

    const [vehicles, setVehicles] = useState([]);

    const [vehRegCode, setVehRegCode] = useState("");
    const [modelCode, setModelCode] = useState("");
    const [modelName, setModelName] = useState("");
    const [dateManufacture, setDateManufacture] = useState("");
    const [countryManufature, setCountryManufature] = useState("");
    const [chassisNo, setChassisNo] = useState("");
    const [engineNo, setEngineNo] = useState("");
    const [vehicleRemarks, setVehicleRemarks] = useState("");
    const [vehicleDocCode, setVehicleDocCode] = useState('');
    const [plateNo, setPlateNo] = useState('');
    const [vehicleDateRegistration, setVehicleDateRegistration] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [generalDocs, setGeneralDocs] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingVehReg, setIsProcessingVehReg] = useState(false);
    const [vehicleDocs, setVehicleDocs] = useState([]);

    const [loading, setLoading] = useState(false);

    const fileInputRef = useRef(null);
    const vehicleFileInputRef = useRef(null);

    const saveMemberDetails = (e) => {
        e.preventDefault();

        if (membershipTypeCode.trim().length === 0) { toast.warn('Please Select a Membership Category'); return; }
        if (membershipNo.trim().length === 0) { toast.warn('Please Enter Membership #'); return; }
        if (cusName.trim().length === 0) { toast.warn('Please Enter Member Name'); return; }
        if (cusMobile.trim().length === 0) { toast.warn('Please Enter Mobile #'); return; }
        if (paymentStatus.trim().length === 0) { toast.warn('Please Select Payment Status'); return; }
        if (membershipDate === '1900/01/01') { toast.warn('Please Enter Membership Date'); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        memberAPI.saveMemeberDetails(cusCode, membershipTypeCode, selectedPcode, membershipNo, membershipDate, outstanding, comments,
            cusName, cusAdd, cusEmail, cusTel, cusMobile, paymentStatus).then((data) => {
                toast.success("Successfully saved");
                setCusCode(data.cusCode);
            }).finally(() => setIsProcessing(false));
    }

    const saveMemeberVehicle = () => {
        if (cusCode.trim().length === 0) { toast.warn('Please Select a Member'); return; }
        if (modelCode.trim().length === 0) { toast.warn('Please Select a Vehicle Model'); return; }
        if (dateManufacture.trim().length === 0) { toast.warn('Please Select a Manufacture Date'); return; }
        if (countryManufature.trim().length === 0) { toast.warn('Please Select a Manufacture Country'); return; }
        if (vehicleDateRegistration.trim().length === 0) { toast.warn('Please Select Vehicle Registration Date'); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessingVehReg(true);

        memberAPI.saveMemeberVehicle(cusCode, vehRegCode, modelCode, dateManufacture, countryManufature, engineNo, chassisNo, plateNo,
            vehicleRemarks, selectedPcode, vehicleDateRegistration).then((data) => {
                toast.success("Successfully saved");
                setVehRegCode(data.vehRegCode);
                if (cusCode) {
                    getCustomerDetails(cusCode);
                }
            }).finally(() => setIsProcessingVehReg(false));
    }

    const resetForm = () => {
        setMembershipTypeCode('');
        setMembershipTypeName('');
        setCusCode('');
        setMembershipNo('');

        setMembershipDate('1900/01/01');
        setOutstanding(0);
        setComments("");

        setCusName('');
        setCusAdd('');
        setCusEmail('');
        setCusTel('');
        setCusMobile('');
        setPaymentStatus('');

        setGeneralDocCode("");
        setSelectedFile(null);

        setVehicles([]);
    }


    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);
        }
    };

    const addGeneralDocuments = async () => {
        if (generalDocCode === '') {
            toast.error("Please select a document type.");
            return;
        }

        if (!selectedFile) {
            toast.error("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("generalDocCode", generalDocCode);
        formData.append("cusCode", cusCode);

        setLoading(true);
        try {
            fileUploadAPI.saveMemberDocs(formData).then((res) => {
                toast.success("Member document uploaded successfully!");

                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                };

                setGeneralDocCode("");
                setSelectedFile(null);
                if (cusCode) {
                    getUploadedGeneralDocs(cusCode);
                }

                setLoading(false);
            });
        } catch (e) {
            toast.error("Error occured while uploading the file.");
        }
    };

    const getUploadedGeneralDocs = (cusCode) => {
        fileUploadAPI.getGeneralDocs(cusCode).then((filesData) => {
            setGeneralDocs(filesData);
            setLoading(false);
        }).catch((e) => {
            console.error("Error fetching files:", e);
        })
    };

    useEffect(() => {
        if (cusCode) {
            getUploadedGeneralDocs(cusCode);
        }
    }, [cusCode]);

    const deleteGeneralDocs = async (fileId) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            try {
                await fileUploadAPI.deleteGeneralDocs(fileId);
                toast.success("Document deleted successfully");
                getUploadedGeneralDocs(cusCode);
            } catch (e) {
                console.error("Error occurred while deleting the document!", e);
                toast.error("Failed to delete document");
            }
        }
    };

    const addVehicleDocuments = async () => {
        if (!vehicleDocCode) {
            toast.error("Please select a document type.");
            return;
        }

        if (!selectedFile) {
            toast.error("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("vehicleDocCode", vehicleDocCode);
        formData.append("cusCode", cusCode);
        formData.append("vehRegCode", vehRegCode);

        setLoading(true);
        try {
            fileUploadAPI.saveVehicleDocs(formData).then((res) => {
                toast.success("Vehicle document uploaded successfully!");

                if (vehicleFileInputRef.current) {
                    vehicleFileInputRef.current.value = '';
                };

                setVehicleDocCode("");
                setSelectedFile(null);

                if (cusCode && vehRegCode) {
                    getUploadedVehicleDocs(cusCode, vehRegCode);
                }
            });
        } catch (e) {
            toast.error("Error occured while uploading the file.");
        }
    }

    const getUploadedVehicleDocs = (cusCode, vehRegCode) => {
        fileUploadAPI.getVehicleDocs(cusCode, vehRegCode).then((filesData) => {
            setVehicleDocs(filesData);
            setLoading(false);
        }).catch((e) => {
            console.error("Error fetching files:", e);
        })
    };

    useEffect(() => {
        if (cusCode && vehRegCode) {
            getUploadedVehicleDocs(cusCode, vehRegCode);
        }
    }, [cusCode, vehRegCode]);

    const deleteVehicleDocs = async (fileId) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            try {
                await fileUploadAPI.deleteVehicleDocs(fileId);
                toast.success("Document deleted successfully");
                getUploadedVehicleDocs(cusCode, vehRegCode);
            } catch (e) {
                console.error("Error occurred while deleting the document!", e);
                toast.error("Failed to delete document");
            }
        }
    };

    // load vehicle registration modal by clearing existing state
    const openVehRegistrationModal = (vehRegCode) => {
        const modalElement = document.getElementById('modalMemberVehRegistration');

        if (modalElement) {
            setVehRegCode(vehRegCode);
            const modal = new window.bootstrap.Modal(modalElement); modal.show();

            getVehicleDetails(cusCode, vehRegCode);
        }
    }

    // load vehicle registration modal without clearing existing state (this is mainly used when redirecting from vehicle model)
    const openVehRegistrationModalFromVehicleModel = (vehRegCode) => {
        const modalElement = document.getElementById('modalMemberVehRegistration');

        if (modalElement) {
            setVehRegCode(vehRegCode);
            const modal = new window.bootstrap.Modal(modalElement); modal.show();
        }
    }

    const getCustomerDetails = (cusCode) => {
        if (cusCode.trim().length === 0) { return; }

        setIsProcessing(true);
        memberAPI.getMemberDetails(cusCode, selectedPcode).then((data) => {
            setMembershipTypeCode(data.members[0].membershipTypeCode);
            setMembershipTypeName(data.members[0].membershipTypeName);
            setMembershipNo(data.members[0].membershipNo);
            setCusName(data.members[0].cusName);
            setCusAdd(data.members[0].cusAdd);
            setCusEmail(data.members[0].cusEmail);
            setCusTel(data.members[0].cusTel);
            setCusMobile(data.members[0].cusMobile);
            setMembershipDate(common.convertToDate(data.members[0].membershipDate));
            setOutstanding(data.members[0].outstanding);
            setComments(data.members[0].comments);
            setPaymentStatus(data.members[0].paymentStatus);

            setVehicles(data.vehicles);
        }).finally(() => setIsProcessing(false));
    }

    const getVehicleDetails = (cusCode, vehRegCode) => {
        clearVehicleDetails();

        if (cusCode.trim().length === 0 || vehRegCode.trim().length === 0) { return; }

        setIsProcessingVehReg(true);

        memberAPI.getVehicleDetails(cusCode, vehRegCode, selectedPcode).then((data) => {
            setVehRegCode(vehRegCode);
            setModelCode(data.vehicle[0].modelCode);
            setModelName(data.vehicle[0].modelName);
            setDateManufacture(common.convertToDate(data.vehicle[0].manufactureDate));
            setVehicleDateRegistration(common.convertToDate(data.vehicle[0].vehRegDate));
            setCountryManufature(data.vehicle[0].country);
            setEngineNo(data.vehicle[0].engineNo);
            setChassisNo(data.vehicle[0].chassisNo);
            setPlateNo(data.vehicle[0].plateNo);
            setVehicleRemarks(data.vehicle[0].note);
        }).finally(() => setIsProcessingVehReg(false));
    }

    const clearVehicleDetails = () => {
        setVehRegCode("");
        setDateManufacture("");
        setCountryManufature("");
        setEngineNo("");
        setChassisNo("");
        setPlateNo("");
        setVehicleRemarks("");
        setVehicleDateRegistration("");
        setModelCode("");
        setModelName("");

        setVehicleDocCode("");
        setSelectedFile(null);
    }

    return (
        <>
            {
                selectedPcode &&
                <MemberSearch setCusCode={setCusCode} triggerAfterSelected={getCustomerDetails} pcode={selectedPcode} />
            }
            {
                selectedPcode &&
                <MembershipTypeSearch setMembershipTypeCode={setMembershipTypeCode} setMembershipTypeName={setMembershipTypeName}
                    pcode={selectedPcode} />
            }
            <div className="container form__body">
                {isProcessing ? <div className="text-center"><Spinner /></div> :
                    <form>
                        <div className='row mt-1'>
                            <h4 className='text-center'>Member Registration</h4>
                            <div className='col-xs-12 col-sm-12 col-md-8 col-lg- col-xl-8 col-xxl-8'>
                                <h6 className="text-success fw-bold lable__nextStep">Step 1 : Member Details</h6>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="usr" className='h6'>Center</label>
                                            <AllPcode setSelectedPcode={setSelectedPcode} triggerOnChangePCode={resetForm} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <label htmlFor="usr" className='h6'>Code</label>
                                    <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={cusCode} readOnly placeholder="Auto" />
                                        </div>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                        <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                            data-bs-target="#modalMemberSearch"><span className="small">Find</span></button>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 ps-md-0">
                                        <div className="form-group">
                                            <select className="form-select text-danger" onChange={(e) => setPaymentStatus(e.target.value)}
                                                value={paymentStatus}>
                                                <option value="">Select a Payment Status</option>
                                                <option value="paid">Paid</option>
                                                <option value="unpaid">Unpaid</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <label htmlFor="usr" className='h6'>Membership Category <span className="text-danger">*</span></label>
                                    <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={membershipTypeCode} readOnly />
                                        </div>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                        <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                            data-bs-target="#modalMembershipypeSearch"><span className="small">Find</span></button>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 ps-md-0">
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={membershipTypeName} readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Membership # <span className="text-danger">*</span></label>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={membershipNo} maxLength="50" onChange={(e) => setMembershipNo(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Member Name <span className="text-danger">*</span></label>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={cusName} maxLength="100" onChange={(e) => setCusName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Address</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={cusAdd} maxLength="200" onChange={(e) => setCusAdd(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Email</label>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                value={cusEmail} maxLength="30" onChange={(e) => setCusEmail(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Mobile # <span className="text-danger">*</span></label>
                                        <div className="form-group">
                                            <input type="tel" className="form-control"
                                                value={cusMobile} maxLength="20" onChange={(e) => setCusMobile(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Phone #</label>
                                        <div className="form-group">
                                            <input type="tel" className="form-control"
                                                value={cusTel} maxLength="20" onChange={(e) => setCusTel(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Date of Membership <span className="text-danger">*</span></label>
                                        <div className="form-group">
                                            <input type="date" className="form-control"
                                                value={membershipDate} onChange={(e) => setMembershipDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <label htmlFor="usr" className='h6'>Outstanding</label>
                                        <div className="form-group">
                                            <input type="number" className="form-control text-end"
                                                value={outstanding} onChange={(e) => setOutstanding(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <label htmlFor="usr" className='h6'>Comments</label>
                                        <div className="form-group">
                                            <textarea value={comments} className="form-control" maxLength="500"
                                                onChange={(e) => setComments(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <div className='col-12 col-sm-12 col-md-6'>
                                        <button type="button" className="btn btn-dark form-control mt-1" onClick={saveMemberDetails}>Save</button>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-6'>
                                        <button type="button" className="btn btn-secondary form-control mt-1" onClick={resetForm}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                <h6 className="text-success fw-bold lable__nextStep mt-3 mt-sm-3 mt-md-0">Step 2 : Document Upload / Vehicle Reg.</h6>
                                {cusCode.length !== 0 ?
                                    <div className=" documents__vehicles">
                                        <p className="fw-bold">General Documents</p>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <DocumentType
                                                        setDocumentTypeCode={setGeneralDocCode}
                                                        documentTypeCode={generalDocCode} />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        ref={fileInputRef}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 text-end">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary btn-sm"
                                                        onClick={addGeneralDocuments}
                                                        disabled={loading}
                                                    >
                                                        {loading ? (
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Spinner
                                                        ) : (
                                                            "Add +"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <table className="table">
                                                    <thead>
                                                        <th>Document Type</th>
                                                        <th>Path</th>
                                                        <th></th>
                                                    </thead>
                                                    <tbody>
                                                        {generalDocs.length > 0 ? (
                                                            generalDocs.map((file) => (
                                                                <tr key={file.fileId}>
                                                                    <td>{file.fileName}</td>
                                                                    <td>
                                                                        <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
                                                                            <i
                                                                                className="fa fa-eye"
                                                                                aria-hidden="true"
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '20px',
                                                                                    color: 'black',
                                                                                    marginLeft: '6px'
                                                                                }}
                                                                            ></i>
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <i
                                                                            className="fa fa-trash"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                fontSize: '20px',
                                                                                color: 'black',
                                                                                marginLeft: '6px'
                                                                            }}
                                                                            onClick={() => deleteGeneralDocs(file.fileId)}
                                                                        ></i>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="3">No documents found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <p className="fw-bold mt-2">Vehicle Details</p>
                                        <div className="row">
                                            <div className="col-12">
                                                <table className="table">
                                                    <thead>
                                                        <th>Plate #</th>
                                                        <th>Model</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            vehicles && vehicles.map((data) => (
                                                                <tr>
                                                                    <td>{data.vehRegCode}</td>
                                                                    <td>{data.modelName}</td>
                                                                    <td><button className="btn btn-sm btn-secondary"
                                                                        type="button"
                                                                        onClick={() => openVehRegistrationModal(data.vehRegCode, true)}>
                                                                        View</button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 text-end">
                                                <div className="form-group">
                                                    <button type="button" className="btn btn-secondary btn-sm"
                                                        onClick={() => openVehRegistrationModal('', true)}
                                                    >Add New Vehicle</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <p className="label_memeberDetails text-center">Awaiting Member Details...</p>}
                            </div>
                        </div>
                    </form>
                }
            </div >

            <div className="modal fade" id="modalMemberVehRegistration" tabIndex="-1" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-xl mt-0">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">New Vehicle Registration</h5>
                            {isProcessingVehReg === false ? <button type="button" className="btn-close" data-bs-dismiss="modal"></button> : ""}
                        </div>
                        <div className="modal-body">
                            {isProcessingVehReg === true ? <div className="text-center"><Spinner /></div> :
                                <div className="row">
                                    <div className="col-0 col-sm-0 col-md-0"></div>
                                    <div className="col-12 col-sm-12 col-md-8">
                                        <h6 className="text-success fw-bold lable__nextStep">Step 3 : Vehicle Details</h6>
                                        <div className="row mt-1">
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <label htmlFor="usr" className='h6'>Member Code <span className="text-danger">*</span></label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={cusCode} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-8">
                                                <label htmlFor="usr" className='h6'>Member Name</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={cusName} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <label htmlFor="usr" className='h6'>Registration Code</label>
                                                <div className="form-group">
                                                    <input type="text" value={vehRegCode} readOnly className="form-control" placeholder="Auto" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <label htmlFor="usr" className='h6'>Vehicle Model <span className="text-danger">*</span></label>
                                            <div className="col-8 col-sm-8 col-md-4 pe-0 me-0">
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={modelCode} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-4 col-sm-4 col-md-1 text-center px-0">
                                                <button type="button" className="btn btn-sm btn-secondary m-0 px-sm-1" data-bs-toggle="modal"
                                                    data-bs-target="#modalVehicleModelSearch"><span className="small">Find</span></button>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-7 ps-md-0">
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={modelName} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <label htmlFor="usr" className='h6'>Date of Manufacture  <span className="text-danger">*</span></label>
                                                <div className="form-group">
                                                    <input type="date" className="form-control"
                                                        value={dateManufacture} onChange={(e) => setDateManufacture(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6">
                                                <label htmlFor="usr" className='h6'>Country of Manufacture <span className="text-danger">*</span></label>
                                                <div className="form-group">
                                                    <Countries setCountryCode={setCountryManufature} countryCode={countryManufature} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <label htmlFor="usr" className='h6'>Engine #</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={engineNo} onChange={(e) => setEngineNo(e.target.value)} maxLength="50" />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6">
                                                <label htmlFor="usr" className='h6'>Chassis #</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={chassisNo} onChange={(e) => setChassisNo(e.target.value)} maxLength="50" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <label htmlFor="usr" className='h6'>Plate #</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                        value={plateNo} onChange={(e) => setPlateNo(e.target.value)} maxLength="15" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <label htmlFor="usr" className='h6'>Date of Registration <span className="text-danger">*</span></label>
                                                <div className="form-group">
                                                    <input type="date" className="form-control"
                                                        value={vehicleDateRegistration}
                                                        onChange={(e) => setVehicleDateRegistration(e.target.value)} maxLength="15" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12 col-sm-12 col-md-12">
                                                <label htmlFor="usr" className='h6'>Note</label>
                                                <div className="form-group">
                                                    <textarea className="form-control" value={vehicleRemarks}
                                                        onChange={(e) => setVehicleRemarks(e.target.value)} maxLength="200"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-0 col-sm-0 col-md-6"></div>
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <button className="btn btn-dark form-control" type="button" onClick={() => saveMemeberVehicle()}>
                                                    Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4">
                                        <h6 className="text-success fw-bold lable__nextStep">Step 4 : Vehicle Documents</h6>
                                        {vehRegCode.length !== 0 ?
                                            <div class="row mt-2">
                                                <div className='col-12 col-sm-12 col-md-12'>
                                                    <p className="fw-bold">Vehicle Documents</p>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                                <DocumentType setDocumentTypeCode={setVehicleDocCode} documentTypeCode={vehicleDocCode} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                                <input
                                                                    type="file"
                                                                    onChange={handleFileChange}
                                                                    ref={vehicleFileInputRef}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 text-end">
                                                            <div className="form-group">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary btn-sm"
                                                                    onClick={addVehicleDocuments}
                                                                    disabled={loading}
                                                                >
                                                                    {loading ? (
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Spinner
                                                                    ) : (
                                                                        "Add +"
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <table className="table">
                                                                <thead>
                                                                    <th>Document Type</th>
                                                                    <th>Path</th>
                                                                    <th></th>
                                                                </thead>
                                                                <tbody>
                                                                    {vehicleDocs.length > 0 ? (
                                                                        vehicleDocs.map((file) => (
                                                                            <tr key={file.fileId}>
                                                                                <td>{file.fileName}</td>
                                                                                <td>
                                                                                    <a href={file.fileUrl} target="_blank" rel="noopener noreferrer">
                                                                                        <i
                                                                                            className="fa fa-eye"
                                                                                            aria-hidden="true"
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                                fontSize: '20px',
                                                                                                color: 'black'
                                                                                            }}
                                                                                        ></i>
                                                                                    </a>
                                                                                </td>
                                                                                <td>
                                                                                    <i
                                                                                        className="fa fa-trash"
                                                                                        aria-hidden="true"
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            fontSize: '20px',
                                                                                            color: 'black',
                                                                                            marginLeft: '6px'
                                                                                        }}
                                                                                        onClick={() => deleteVehicleDocs(file.fileId)}
                                                                                    ></i>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="3">No documents found</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <p className="label_memeberDetails text-center">Awaiting Vehicle Details...</p>}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <VehicleModelSearch setModelCode={setModelCode} setModelName={setModelName} triggerAfterSelected={openVehRegistrationModalFromVehicleModel}
                vehRegCode={vehRegCode} />
        </>
    )
}